.loading-wrapper {
    position: fixed;
    z-index: 1000000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.pos {
    position: relative;
    left: 43%;
    top: 50%;
    width: 150px;
}

.loading-image {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Adjust the size of the image as needed */
    /* width: 100px; */
    /* height: 100px; */
}